<template>
  <div>
    <var data-mark />
    <!-- <v-img :src="randomImage" class="image" v-bind:name="randomImage" /> -->
    <img class="image" :src="randomImage" />
    <h1>Coming Soon.</h1>
    <h2>即使如此，零風還是</h2>
  </div>
</template>

<script>
export default {
  name: "ComingSoon",
  data() {
    const images = [
      require('@/assets/umr_working.gif')
    ];
    const randomImage = images[Math.floor(Math.random() * images.length)]
      return { randomImage }
  },
  async mounted() {
    await this.$nextTick();
    this.$root.$emit("pageload");
  },
};
</script>

<style lang="scss" scoped>
div {
  text-align: center;
}

h1 {
  font-size: 4rem;
  line-height: 3rem;
  margin: 4rem 0 0;
  padding: 0;
}

h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 2rem 0;
}

.image {
  background: transparent center no-repeat;
  background-size: contain;
  margin: 0 auto;
  width: 24rem;
}
</style>