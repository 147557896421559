<template>
  <div class="hexGrid">
    <div class="title">
      <h1>?</h1>
      <h2>Past, present, Future.</h2>
    </div>
    <br />
    <div>
      <ul id="hexGrid">
        <li class="hex">
          <div class="hexIn">
            <a class="hexLink">
              <img :src="require('@/assets/avatar/forSta04.webp')" />
              <h1>ForSta04</h1>
              <p>我是路人甲</p>
            </a>
          </div>
        </li>
        <li class="hex">
          <div class="hexIn">
            <a class="hexLink">
              <img :src="require('@/assets/avatar/jokefroglet.gif')" />
              <h1>Jokefroglet</h1>
              <p>Unknown.</p>
            </a>
          </div>
        </li>
        <li class="hex">
          <div class="hexIn">
            <a class="hexLink">
              <img :src="require('@/assets/avatar/nomore.png')" />
              <h1>Lomore</h1>
              <p>I go to school by bus.</p>
            </a>
          </div>
        </li>
        <li class="hex">
          <div class="hexIn">
            <a class="hexLink">
              <img :src="require('@/assets/avatar/curitl.gif')" />
              <h1>Ezonda</h1>
              <p>呃蝦條全攻略 - 耿卜教育</p>
            </a>
          </div>
        </li>
        <li class="hex">
          <div class="hexIn">
            <a class="hexLink">
              <img :src="require('@/assets/avatar/LunHang.gif')" />
              <h1>LunHang</h1>
              <p>希望在明天。</p>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "unk",
  async mounted() {
    await this.$nextTick();
    this.$root.$emit("pageload");
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
  // font-family: "Microsoft JhengHei", "Arial", sans-serif;
}

.title h1 {
  font-size: 4rem;
  line-height: 3rem;
  margin: 4rem 0 0;
  padding: 0;
}

.title h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 2rem 0;
  color: transparent;
}

.hexGrid {
  display: block;
  width: 95%;
}

* {
  margin: 0;
  padding: 0;
}
body {
  background: rgb(123, 158, 158);
}
#hexGrid {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  overflow: hidden;
  font-family: "Open Huninn", "Microsoft JhengHei";
  font-size: 15px;
  list-style-type: none;
}

.hex {
  position: relative;
  visibility: hidden;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
}
.hex::after {
  content: "";
  display: block;
  padding-bottom: 86.602%; /* =  100 / tan(60) * 1.5 */
}
.hexIn {
  position: absolute;
  width: 96%;
  padding-bottom: 110.851%; /* =  width / sin(60) */
  margin: 0 2%;
  overflow: hidden;
  visibility: hidden;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
  -webkit-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  -ms-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
}
.hexIn * {
  position: absolute;
  visibility: visible;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
}
.hexLink {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  overflow: hidden;
  -webkit-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  -ms-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
}

/*** HEX CONTENT **********************************************************************/
.hex img {
  left: -100%;
  right: -100%;
  width: auto;
  height: 100%;
  margin: 0 auto;
  -webkit-transform: rotate3d(0, 0, 0, 0deg);
  -ms-transform: rotate3d(0, 0, 0, 0deg);
  transform: rotate3d(0, 0, 0, 0deg);
}

.hex h1,
.hex p {
  width: 100%;
  padding: 5%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.75);
  font-weight: 300;
  -webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.3s ease-out;
  transition: transform 0.2s ease-out, opacity 0.3s ease-out;
}
.hex h1 {
  bottom: 50%;
  padding-top: 50%;
  font-size: 1.5em;
  z-index: 1;
  -webkit-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}
.hex h1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 45%;
  width: 10%;
  text-align: center;
  border-bottom: 1px solid #fff;
}
.hex p {
  top: 50%;
  padding-bottom: 50%;
  -webkit-transform: translate3d(0, 100%, 0);
  -ms-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

/*** HOVER EFFECT  **********************************************************************/
.hexLink:hover h1,
.hexLink:focus h1,
.hexLink:hover p,
.hexLink:focus p {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
@media (min-width: 1201px) {
  /* <- 5-4  hexagons per row */
  #hexGrid {
    padding-bottom: 4.4%;
  }
  .hex {
    width: 20%; /* = 100 / 5 */
  }
  .hex:nth-child(9n + 6) {
    /* first hexagon of even rows */
    margin-left: 10%; /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 1200px) and (min-width: 901px) {
  /* <- 4-3  hexagons per row */
  #hexGrid {
    padding-bottom: 5.5%;
  }
  .hex {
    width: 25%; /* = 100 / 4 */
  }
  .hex:nth-child(7n + 5) {
    /* first hexagon of even rows */
    margin-left: 12.5%; /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 900px) and (min-width: 601px) {
  /* <- 3-2  hexagons per row */
  #hexGrid {
    padding-bottom: 7.4%;
  }
  .hex {
    width: 33.333%; /* = 100 / 3 */
  }
  .hex:nth-child(5n + 4) {
    /* first hexagon of even rows */
    margin-left: 16.666%; /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 600px) {
  /* <- 2-1  hexagons per row */
  #hexGrid {
    padding-bottom: 11.2%;
  }
  .hex {
    width: 50%; /* = 100 / 3 */
  }
  .hex:nth-child(3n + 3) {
    /* first hexagon of even rows */
    margin-left: 25%; /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 400px) {
  #hexGrid {
    font-size: 13px;
  }
}
</style>