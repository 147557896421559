import Vue from 'vue'
import VueRouter from 'vue-router'

const AboutPage = () =>
    import ('../pages/AboutPage')
const NotFoundPage = () =>
    import ('../pages/NotFoundPage')
const ComingSoon = () =>
    import ('../pages/ComingSoon')
const MemberPage = () =>
    import ('../pages/MemberPage')

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'index',
        component: ComingSoon
    },
    // {
    //     path: '/about',
    //     name: 'about',
    //     component: AboutPage,
    //     meta: { title: '關於' },
    // },
    {
        path: '/unk',
        name: '?',
        component: MemberPage,
        meta: { title: '?' },
    },
    {
        path: '*',
        name: 'not-found',
        component: NotFoundPage,
        meta: { title: '404' },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router