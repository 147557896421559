<template>
  <div id="app">
    <div class="content">
      <main>
        <loader v-if="!loaded" />
        <router-view v-if="loaded" />
      </main>
      <footer>
        <div>
          <span>&copy; {{ year }} 零．風</span>
          <div class="disclaimer">
            所有資源及程序等均來源於網絡，
            <br/>
            旨在研究以及學習程序當中的結構。
          </div>
        </div>
      </footer>
    </div>
    <background />
  </div>
</template>
<style lang="scss">
@import "./assets/font/font.css";

html,
body {
  overflow-x: hidden;
}

html {
  //background: #111927;
  overflow-y: scroll;
}

body {
  //background: linear-gradient(45deg, #361c03, #271111);
  //background: url("~@/assets/51010.jpg") no-repeat fixed center top;
  background: url("~@/assets/bg.jpg") no-repeat fixed center top;
  background-size: cover;
  color: #FFFFFF;
  font: 1rem "Open Huninn", "Helvetica", "Microsoft JhengHei", "Arial", sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.6rem;
  margin: 0;
  overflow-y: hidden;
}

a {
  color: #b5dff5;

  &.link {
    //border-bottom: 1px dashed transparent;
    padding-top: 3px;
    padding-bottom: 2px;
    text-decoration: none;
    transition: border-color 0.2s;

    &:hover {
      border-bottom-color: currentColor;
    }
  }
}

.content {
  align-items: center;
  //background: url(./assets/g-content.png);
  background: rgba(0, 0, 0, 0.4);
  background-size: 100% 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 90vh;
  max-width: 1536px;
  padding-bottom: env(safe-area-inset-bottom);
  position: relative;
  margin: 2.5rem auto;
  z-index: 2;

  &:before {
    //background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAEAQMAAACTPww9AAAABlBMVEUAAAD///+l2Z/dAAAAAnRSTlMAFoJHeGkAAAAQSURBVAjXYxBgUGBwYGgAAAJYAPF9gAMvAAAAAElFTkSuQmCC);
    border-radius: 8px;
    content: "";
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  // smaller than container
  @media (max-width: calc(1536px - 1px)) {
    margin: 0 auto;
    min-height: 100vh;

    &,
    &:before {
      border-radius: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
main {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;

  > .page {
    margin-bottom: auto;
    width: 100%;
  }
}

footer {
  align-items: center;
  display: flex;
  font-size: 0.8rem;
  height: 12rem;
  justify-content: center;
  text-align: center;
  width: 100%;

  ins {
    background: rgba(210, 229, 247, 0.6);
    border-radius: 50%;
    display: inline-block;
    height: 3px;
    margin: 0.2rem 0.4rem;
    width: 3px;
  }

  .disclaimer {
    color: #d8d8d8;
  }

  .links {
    margin-top: 1rem;
    padding: 0 4rem;
  }
}
</style>

<script>
import Background from "./components/Background";
import Loader from "./components/Loader";

export default {
  name: "App",
  components: {
    Background,
    Loader,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      loaded: true,
    };
  }
};
</script>