import Vue from 'vue'
import qs from 'qs-lite'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

const siteTitle = document.title
const siteDescription = document.querySelector('meta[name="description"]').content

let pageLoadResolver
let pageLoadPromise = new Promise((resolve) => { pageLoadResolver = resolve })

router.afterEach(async(to) => {
            pageLoadPromise = new Promise((resolve) => { pageLoadResolver = resolve })
            const { title } = to.meta
            document.title = `${title ? `${title} - ` : ''}${siteTitle}`
  document.querySelector('meta[name="description"]').content = siteDescription
  const url = `${to.path}${Object.keys(to.query).length > 0 ? '?' : ''}${qs.stringify(to.query)}`

  await pageLoadPromise

  window.requestAnimationFrame(() => {
    window.ga('set', 'page', url)
    window.ga('send', 'pageview')
    window.dispatchEvent(new CustomEvent('routechange'))
  }, 50)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')