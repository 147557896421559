<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="66px" height="66px" viewBox="0 0 66 66">
      <g transform="rotate(251.552 33 33)">
        <animateTransform attributeName="transform" type="rotate" values="0 33 33;270 33 33" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite" />
        <circle fill="none" stroke-width="5" stroke-linecap="round" cx="33" cy="33" r="30" stroke-dasharray="187" stroke-dashoffset="610" transform="rotate(406.955 33 33)">
          <animate attributeName="stroke" values="#4285F4;#DE3E35;#F7C223;#1B9A59;#4285F4" begin="0s" dur="5.6s" fill="freeze" repeatCount="indefinite" />
          <animateTransform attributeName="transform" type="rotate" values="0 33 33;135 33 33;450 33 33" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite" />
          <animate attributeName="stroke-dashoffset" values="187;46.75;187" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite" />
        </circle>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'loader',
}
</script>

<style lang="scss" scoped>
</style>
